<template>
  <div style="box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;" class="navbar-container content noPrint bg-white mb-1">
    <nav class="d-none d-xl-block">
      <ul class="admin-menu">
        <!--  inventory menu start  -->
        <li class="menu-main-li" v-if="canAny([
            'warehouse-view-any','warehouse-create',
            'product-item-view-any','product-item-create',
            'product-item-tracking-view-any',])">
          <a href="#" class="d-flex align-items-lg-top align-items-xl-center">
            <i class='bx bxs-dashboard icon-right-spacing'></i>
            <span>Inventories</span>
            <i class="bx bx-chevron-down nav-arrow-right-icon icon-left-spacing "></i>
          </a>
          <ul class="admin-submenu">
            <!--  inventory:warehouse menu start  -->
            <li v-if="canAny(['warehouse-view-any','warehouse-create'])">
              <a href="#" class="d-flex align-items-center justify-content-between">Warehouses
                <i class="bx bx-chevron-right nav-arrow-right-icon"></i></a>
              <ul class="admin-submenu2">
                <li v-if="can('warehouse-view-any')">
                  <router-link :to="{name:'appWarehouseList'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">All Warehouse</span>
                  </router-link>
                </li>
                <li v-if="can('warehouse-create')">
                  <router-link :to="{name:'appWarehouseCreate'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">Add Warehouse</span>
                  </router-link>
                </li>
              </ul>
            </li>
            <!--  inventory:warehouse menu end  -->

            <!--  inventory:product item menu start  -->
            <li v-if="canAny(['product-item-view-any','product-item-create'])">
              <a href="#" class="d-flex align-items-center justify-content-between">Product Items
                <i class="bx bx-chevron-right nav-arrow-right-icon"></i></a>
              <ul class="admin-submenu2">
                <li v-if="can('product-item-view-any')">
                  <router-link :to="{name:'appProductItemList'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">All Product Item</span>
                  </router-link>
                </li>
                <li v-if="can('product-item-create')">
                  <router-link :to="{name:'appProductItemCreate'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">Add Product Item</span>
                  </router-link>
                </li>
              </ul>
            </li>
            <!--  inventory:product item menu end  -->

            <!--  inventory:hardware tracking menu start  -->
            <li v-if="canAny(['product-item-tracking-view-any'])">
              <a href="#" class="d-flex align-items-center justify-content-between">Product Item Tracks
                <i class="bx bx-chevron-right nav-arrow-right-icon"></i></a>
              <ul class="admin-submenu2">
                <li v-if="can('product-item-view-any')">
                  <router-link :to="{name:'appProductItemTracking'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">All Product Item Track</span>
                  </router-link>
                </li>

              </ul>
            </li>

            <!-- <li v-if="can('product-item-tracking-view-any')">
              <router-link :to="{name:'appProductItemTracking'}" class="d-flex align-items-center justify-content-between">
                Product Item Tracking
              </router-link>
            </li> -->
            <!--  inventory:hardware tracking menu end  -->
          </ul>
        </li>
        <!--  inventory menu end  -->

        <!--  business menu start  -->
        <li v-if="canAny([
            'appointment-view-any',
            'appointment-create',
            'appointment-customer-list-export',
            'order-view-any', 'order-create',
            'coupon-view-any', 'coupon-create','order-job-view-any',
            'sms-marketing-send'])"
            class="menu-main-li">
          <a href="" class="d-flex align-items-lg-top align-items-xl-center">
            <i class='bx bxs-business icon-right-spacing'></i> <span>Businesses</span> <i
              class="bx bx-chevron-down nav-arrow-right-icon icon-left-spacing "></i>
          </a>
          <ul class="admin-submenu">
            <!--  business:appointment menu start  -->
            <li v-if="canAny(['appointment-view-any','appointment-create', 'appointment-customer-list-export'])">
              <a href="#" class="d-flex align-items-center justify-content-between">
                Appointments <i class="bx bx-chevron-right nav-arrow-right-icon"></i></a>
              <ul class="admin-submenu2">

                <li v-if="can('appointment-view-any')">
                  <router-link :to="{name:'appAppointmentList'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span
                      class="icon-left-spacing">All Appointment </span></router-link>

                </li>
                <li v-if="can('appointment-create')">
                  <router-link :to="{name:'appAppointmentCreate'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span
                      class="icon-left-spacing">Add Appointment</span></router-link>

                </li>
                <li v-if="can('appointment-create')">
                  <router-link :to="{name:'appAppointmentCostEstimate'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span
                      class="icon-left-spacing">Cost Estimate</span></router-link>

                </li>
                <li v-if="can('appointment-create')">
                  <router-link :to="{name:'appAppointmentReport'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span
                      class="icon-left-spacing">Appointment Report</span></router-link>

                </li>
                <li v-if="can('appointment-customer-list-export')">
                  <router-link :to="{name:'appAppointmentExportsCustomers'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span
                      class="icon-left-spacing">Export Customers</span></router-link>

                </li>

              </ul>

            </li>
            <!--  business:appointment menu end  -->

            <!--  business:appointment closer menu start  -->
            <li v-if="canAny(['appointment-consent-view-any'])">
              <a href="#" class="d-flex align-items-center justify-content-between">
                Appointment Closers <i class="bx bx-chevron-right nav-arrow-right-icon"></i></a>
              <ul class="admin-submenu2">

                <li v-if="can('appointment-consent-view-any')">
                  <router-link :to="{name:'appAppointmentClosers'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span
                      class="icon-left-spacing">All Appointment Closer </span></router-link>
                </li>
              </ul>

            </li>
            <!--  business:appointment closer menu end  -->


            <!--  business:appointment service agreement menu start  -->
            <li v-if="canAny(['appointment-consent-view-any'])">
              <a href="#" class="d-flex align-items-center justify-content-between">
                Service Agrements <i class="bx bx-chevron-right nav-arrow-right-icon"></i></a>
              <ul class="admin-submenu2">

                <li v-if="can('appointment-consent-view-any')">
                  <router-link :to="{name:'appointmentServiceAgreements'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span
                      class="icon-left-spacing">All Service Agrement </span></router-link>
                </li>
              </ul>

            </li>
            <!--  business:appointment service agreement menu end  -->

            <!--  business:call request menu start  -->
            <li v-if="canAny(['call-request-create','call-request-view-any'])">
              <a href="#" class="d-flex align-items-center justify-content-between">
                Call Request <i class="bx bx-chevron-right nav-arrow-right-icon"></i></a>
              <ul class="admin-submenu2">

                <li v-if="can('call-request-view-any')">
                  <router-link :to="{name:'appCallRequestList'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span
                      class="icon-left-spacing">All Call Request </span></router-link>
                </li>
                <li v-if="can('call-request-create')">
                  <router-link :to="{name:'appCallRequestCreate'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span
                      class="icon-left-spacing">Call Request Create</span></router-link>
                </li>
              </ul>

            </li>
            <!--  business:call request menu end  -->

            <!--  business:orders menu start  -->
            <li v-if="canAny(['order-view-any','order-create'])">
              <a href="#" class="d-flex align-items-center justify-content-between">Orders
                <i class="bx bx-chevron-right nav-arrow-right-icon"></i></a>
              <ul class="admin-submenu2">
                <li v-if="can('order-view-any')">
                  <router-link :to="{name:'appOrderList'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span
                      class="icon-left-spacing">All Order</span></router-link>
                </li>
                <li v-if="can('order-create')">
                  <router-link :to="{name:'appOrderCreate'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span
                      class="icon-left-spacing">Add New Order</span></router-link>
                </li>
              </ul>
            </li>
            <!--  business:orders menu end  -->

            <li v-if="canAny(['order-job-view-any'])">
              <router-link :to="{name:'appOrderJobList'}">Order Jobs</router-link>
            </li>

            <!--  business:coupon menu start  -->
            <li v-if="canAny(['coupon-view-any','coupon-create','coupon-usage-view-any'])">
              <a href="#" class="d-flex align-items-center justify-content-between">Coupons
                <i class="bx bx-chevron-right nav-arrow-right-icon"></i></a>
              <ul class="admin-submenu2">
                <li v-if="can('coupon-view-any')">
                  <router-link :to="{name:'appCouponList'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span
                      class="icon-left-spacing">All Coupon</span></router-link>
                </li>
                <li v-if="can('coupon-create')">
                  <router-link :to="{name:'appCouponCreate'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span
                      class="icon-left-spacing">Add Coupon</span></router-link>
                </li>
                <li v-if="can('coupon-usage-view-any')">
                  <router-link :to="{name:'appCouponUsageView'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span
                      class="icon-left-spacing">Coupon usages</span></router-link>
                </li>
              </ul>
            </li>
            <!--  business:coupon menu end  -->

            <li v-if="canAny(['sms-marketing-send'])">
              <router-link :to="{name:'appSmsDmSend'}">SMS Digital Marketing</router-link>
            </li>

            <li v-if="canAny(['appointment-create'])">
              <router-link :to="{name:'appAppointmentsSchedule'}">Appointment Schedule</router-link>
            </li>

          </ul>
        </li>
        <!--  business menu end  -->

        <!--products & services menu start-->
        <li v-if="canAny([
            'service-category-view-any',
            'service-view-any', 'service-create',
            'service-tip-view-any',
            'product-category-view-any', 'product-category-create',
            'product-view-any', 'product-create'])"
            class="menu-main-li">
          <a href="#" class="d-flex align-items-lg-top align-items-xl-center">
            <i class='bx bxs-data icon-right-spacing'></i>
            <span>Products & Services</span> <i class="bx bx-chevron-down nav-arrow-right-icon icon-left-spacing "></i>
          </a>
          <ul class="admin-submenu">
            <!--product & service:service category start-->
            <li v-if="canAny(['service-category-view-any'])">
              <a href="#" class="d-flex align-items-center justify-content-between">Service Categories
                <i class="bx bx-chevron-right nav-arrow-right-icon"></i></a>
              <ul class="admin-submenu2">
                <li v-if="can('service-category-view-any')">
                  <router-link :to="{name:'appServiceCategoryList'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">All Service Category</span>
                  </router-link>
                </li>
              </ul>
            </li>
            <!--product & service:service category end-->

            <!--product & service:service start-->
            <li v-if="canAny(['service-view-any', 'service-create'])">
              <a href="#" class="d-flex align-items-center justify-content-between">Services
                <i class="bx bx-chevron-right nav-arrow-right-icon"></i></a>
              <ul class="admin-submenu2">
                <li v-if="can('service-view-any')">
                  <router-link :to="{name:'appServiceList'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">All Service</span>
                  </router-link>
                </li>
                <li v-if="can('service-create')">
                  <router-link :to="{name:'appServiceCreate'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">Add Service</span>
                  </router-link>
                </li>
              </ul>
            </li>
            <!--product & service:service end-->

            <!--product & service:service tip start-->
            <li v-if="canAny(['service-tip-view-any'])">
              <a href="#" class="d-flex align-items-center justify-content-between">Service Tips
                <i class="bx bx-chevron-right nav-arrow-right-icon"></i></a>
              <ul class="admin-submenu2">
                <li v-if="can('service-tip-view-any')">
                  <router-link :to="{name:'appServiceTipList'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">All Service Tip</span>
                  </router-link>
                </li>
              </ul>
            </li>
            <!--product & service:service tip end-->

            <!--product & service:product category start-->
            <li v-if="canAny(['product-category-view-any', 'product-category-create'])">
              <a href="#" class="d-flex align-items-center justify-content-between">Product Categories
                <i class="bx bx-chevron-right nav-arrow-right-icon"></i></a>
              <ul class="admin-submenu2">
                <li v-if="can('product-category-view-any')">
                  <router-link :to="{name:'appProductCategoryList'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">All Product Category</span>
                  </router-link>
                </li>
                <li v-if="can('product-category-create')">
                  <router-link :to="{name:'appProductCategoryCreate'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">Add Product Category</span>
                  </router-link>
                </li>
              </ul>
            </li>
            <!--product & service:product category end-->

            <!--product & service:product start-->
            <li v-if="canAny(['product-view-any','product-create'])">
              <a href="#" class="d-flex align-items-center justify-content-between">Products
                <i class="bx bx-chevron-right nav-arrow-right-icon"></i></a>
              <ul class="admin-submenu2">
                <li v-if="can('product-view-any')">
                  <router-link :to="{name:'appProductList'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">All Product </span>
                  </router-link>

                </li>
                <li v-if="can('product-create')">
                  <router-link :to="{name:'appProductCreate'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">Add Product </span>
                  </router-link>

                </li>
              </ul>
            </li>
            <!--product & service:product end-->
          </ul>
        </li>
        <!--products & services menu end-->

        <!--users menu start-->
        <li v-if="canAny([
            'admin-view-any', 'admin-create',
            'customer-view-any', 'customer-create', 'customer-list-export',
            'franchisee-view-any', 'franchisee-create',
            'technician-view-any', 'technician-create',
            'team-lead-view-any',
            'on-site-technician-view-any',
            'remote-technician-view-any',
            'manager-view-any', 'manager-create',
            'store-manager-view-any',
            'customer-care-view-any', 'customer-care-create',
            'lab-user-view-any', 'lab-user-create',
            'vendor-view-any', 'vendor-create',
            'partner-view-any', 'partner-create',
            'referral-view-any', 'referral-create',
            ])"
            class="menu-main-li">
          <a href="" class="d-flex align-items-lg-top align-items-xl-center">
            <i class='bx bxs-user icon-right-spacing'></i>
            <span>Users</span> <i class="bx bx-chevron-down nav-arrow-right-icon icon-left-spacing "></i>
          </a>
          <ul class="admin-submenu">
            <!--users:customer start-->
            <li v-if="canAny(['customer-view-any', 'customer-create', 'customer-list-export'])">
              <a href="#" class="d-flex align-items-center justify-content-between">Customers
                <i class="bx bx-chevron-right nav-arrow-right-icon"></i></a>
              <ul class="admin-submenu2">
                <li v-if="can('customer-view-any')">
                  <router-link :to="{name:'appCustomerList'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">All Customer</span>
                  </router-link>
                </li>
                <li v-if="can('customer-create')">
                  <router-link :to="{name:'appCustomerCreate'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">Add Customer</span>
                  </router-link>
                </li>
                <li v-if="can('customer-list-export')">
                  <router-link :to="{name:'appCustomerExports'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">Export Customer</span>
                  </router-link>
                </li>
              </ul>
            </li>
            <!--users:customer end-->
            <!--users:referrals start-->
            <li v-if="canAny(['referral-view-any', 'referral-create'])">
              <a href="#" class="d-flex align-items-center justify-content-between">Referrals
                <i class="bx bx-chevron-right nav-arrow-right-icon"></i></a>
              <ul class="admin-submenu2">
                <li v-if="can('referral-view-any')">
                  <router-link :to="{name:'appReferralList'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">All Referral</span>
                  </router-link>
                </li>
                <li v-if="can('referral-create')">
                  <router-link :to="{name:'appReferralCreate'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">Add Referral</span>
                  </router-link>
                </li>

              </ul>
            </li>
            <!--users:referrals end-->
            <!--users:partners start-->
            <li v-if="canAny(['partner-view-any', 'partner-create'])">
              <a href="#" class="d-flex align-items-center justify-content-between">Partners
                <i class="bx bx-chevron-right nav-arrow-right-icon"></i></a>
              <ul class="admin-submenu2">
                <li v-if="can('partner-view-any')">
                  <router-link :to="{name:'appPartnerList'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">All Partner</span>
                  </router-link>
                </li>
                <li v-if="can('partner-create')">
                  <router-link :to="{name:'appPartnerCreate'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">Add partner</span>
                  </router-link>
                </li>

              </ul>
            </li>
            <!--users:partners end-->
            <!--users:franchisee start-->
            <li v-if="canAny(['franchisee-view-any', 'franchisee-create'])">
              <a href="#" class="d-flex align-items-center justify-content-between">Franchisees
                <i class="bx bx-chevron-right nav-arrow-right-icon"></i></a>
              <ul class="admin-submenu2">
                <li v-if="can('franchisee-view-any')">
                  <router-link :to="{name:'appFranchiseeList'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">All Franchisee</span>
                  </router-link>
                </li>
                <li v-if="can('franchisee-create')">
                  <router-link :to="{name:'appFranchiseeCreate'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">Add Franchisee</span>
                  </router-link>
                </li>
              </ul>
            </li>
            <!--users:franchisee end-->
            <!--users:technician start-->
            <li v-if="canAny(['technician-view-any', 'technician-create'])">
              <a href="#" class="d-flex align-items-center justify-content-between">Technicians
                <i class="bx bx-chevron-right nav-arrow-right-icon"></i></a>
              <ul class="admin-submenu2">
                <li v-if="can('technician-view-any')">
                  <router-link :to="{name:'appTechnicianList'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">All Technician</span>
                  </router-link>
                </li>
                <li v-if="can('technician-create')">
                  <router-link :to="{name:'appTechnicianCreate'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">Add Technician</span>
                  </router-link>
                </li>
              </ul>
            </li>
            <!--users:technician end-->
            <!--users:team lead start-->
            <li v-if="canAny(['team-lead-view-any'])">
              <a href="#" class="d-flex align-items-center justify-content-between">Team Lead
                <i class="bx bx-chevron-right nav-arrow-right-icon"></i></a>
              <ul class="admin-submenu2">
                <li v-if="can('team-lead-view-any')">
                  <router-link :to="{name:'appTeamLeadList'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">All Team Lead</span>
                  </router-link>
                </li>
              </ul>
            </li>
            <!--users:team lead end-->
            <!--users:on-site technician start-->
            <li v-if="canAny(['on-site-technician-view-any'])">
              <a href="#" class="d-flex align-items-center justify-content-between">On-Site Technicians
                <i class="bx bx-chevron-right nav-arrow-right-icon"></i></a>
              <ul class="admin-submenu2">
                <li v-if="can('on-site-technician-view-any')">
                  <router-link :to="{name:'appOnSiteTechnicianList'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">All On-Site Technician</span>
                  </router-link>
                </li>
              </ul>
            </li>
            <!--users:on-site technician end-->
            <!--users:remote technician start-->
            <li v-if="canAny(['remote-technician-view-any'])">
              <a href="#" class="d-flex align-items-center justify-content-between">Remote Technicians
                <i class="bx bx-chevron-right nav-arrow-right-icon"></i></a>
              <ul class="admin-submenu2">
                <li v-if="can('remote-technician-view-any')">
                  <router-link :to="{name:'appRemoteTechnicianList'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">All Remote Technician</span>
                  </router-link>
                </li>
              </ul>
            </li>
            <!--users:on-site technician end-->
            <!--users:admin start-->

            <li v-if="canAny(['admin-view-any'])">
              <a href="#" class="d-flex align-items-center justify-content-between">Super Admins
                <i class="bx bx-chevron-right nav-arrow-right-icon"></i></a>
              <ul class="admin-submenu2">
                <li v-if="can('admin-view-any')">
                  <router-link :to="{name:'appSuperAdminList'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span
                      class="icon-left-spacing">All Super Admin</span></router-link>
                </li>
              </ul>
            </li>


            <li v-if="canAny(['admin-view-any','admin-create'])">
              <a href="#" class="d-flex align-items-center justify-content-between">Admins
                <i class="bx bx-chevron-right nav-arrow-right-icon"></i></a>
              <ul class="admin-submenu2">
                <li v-if="can('admin-view-any')">
                  <router-link :to="{name:'appAdminList'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span
                      class="icon-left-spacing">All Admin</span></router-link>
                </li>
                <li v-if="can('admin-create')">
                  <router-link :to="{name:'appAdminCreate'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span
                      class="icon-left-spacing">Add Admin</span></router-link>
                </li>
              </ul>

            </li>

            <!--users:admin end-->
            <!--users:manager start-->
            <li v-if="canAny(['manager-view-any', 'manager-create'])">
              <a href="#" class="d-flex align-items-center justify-content-between">Managers
                <i class="bx bx-chevron-right nav-arrow-right-icon"></i></a>
              <ul class="admin-submenu2">
                <li v-if="can('manager-view-any')">
                  <router-link :to="{name:'appManagerList'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">All Manager </span>
                  </router-link>
                </li>
                <li v-if="can('manager-create')">
                  <router-link :to="{name:'appManagerCreate'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">Add Manager</span>
                  </router-link>
                </li>

              </ul>
            </li>
            <!--users:manager end-->
            <!--users:store manager start-->
            <li v-if="canAny(['store-manager-view-any'])">
              <a href="#" class="d-flex align-items-center justify-content-between">Store Managers
                <i class="bx bx-chevron-right nav-arrow-right-icon"></i></a>
              <ul class="admin-submenu2">
                <li v-if="can('store-manager-view-any')">
                  <router-link :to="{name:'appStoreManagerList'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">All Store Manager</span>
                  </router-link>
                </li>
              </ul>
            </li>
            <!--users:store manager end-->
            <!--users:customer care start-->
            <li v-if="canAny(['customer-care-view-any', 'customer-care-create'])">
              <a href="#" class="d-flex align-items-center justify-content-between">Customer Cares
                <i class="bx bx-chevron-right nav-arrow-right-icon"></i></a>
              <ul class="admin-submenu2">
                <li v-if="can('customer-care-view-any')">
                  <router-link :to="{name:'appCustomerCareList'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">All Customer Care</span>
                  </router-link>
                </li>
                <li v-if="can('customer-care-create')">
                  <router-link :to="{name:'appCustomerCareCreate'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">Add Customer Care</span>
                  </router-link>
                </li>
              </ul>
            </li>
            <!--users:customer care end-->
            <!--users:lab user start-->
            <li v-if="canAny(['lab-user-view-any', 'lab-user-create'])">
              <a href="#" class="d-flex align-items-center justify-content-between">Lab Users
                <i class="bx bx-chevron-right nav-arrow-right-icon"></i></a>
              <ul class="admin-submenu2">
                <li v-if="can('lab-user-view-any')">
                  <router-link :to="{name:'appLabUserList'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">All Lab User</span>
                  </router-link>
                </li>
                <li v-if="can('lab-user-create')">
                  <router-link :to="{name:'appLabUserCreate'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">Add Lab User</span>
                  </router-link>
                </li>
              </ul>
            </li>
            <!--users:customer care end-->
            <!--users:vendor start-->
            <li v-if="canAny(['vendor-view-any', 'vendor-create'])">
              <a href="#" class="d-flex align-items-center justify-content-between">Vendors
                <i class="bx bx-chevron-right nav-arrow-right-icon"></i></a>
              <ul class="admin-submenu2">
                <li v-if="can('vendor-view-any')">
                  <router-link :to="{name:'appVendorList'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span
                      class="icon-left-spacing">All Vendor</span>
                  </router-link>
                </li>
                <li v-if="can('vendor-create')">
                  <router-link :to="{name:'appVendorCreate'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span
                      class="icon-left-spacing">Add Vendor</span>
                  </router-link>
                </li>
              </ul>
            </li>
            <!--users:vendor end-->
          </ul>
        </li>
        <!--users menu end-->

        <!--  account & finance menu start  -->
        <li class="menu-main-li" v-if="canAny([
            'payment-view-any',
            'cash-payment-view-any',
            'refund-view-any',
            'refund-create',
            'technician-commission-payment-view-any',
            'technician-commission-payment-view',
            ])">
          <a href="#" class="d-flex align-items-lg-top align-items-xl-center">
            <i class='bx bxs-dollar-circle  icon-right-spacing'></i>
            <span>Accounts & Finances</span> <i class="bx bx-chevron-down nav-arrow-right-icon icon-left-spacing "></i>
          </a>
          <ul class="admin-submenu">

            <li v-if="canAny(['payment-view-any'])">
              <a href="#" class="d-flex align-items-center justify-content-between">Payments
                <i class="bx bx-chevron-right nav-arrow-right-icon"></i></a>
              <ul class="admin-submenu2">
                <li v-if="can('payment-view-any')">
                  <router-link :to="{name:'appAccountFinancePaymentsList'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">All Payment</span>
                  </router-link>
                </li>
              </ul>
            </li>

            <li v-if="canAny(['cash-payment-view-any'])">
              <a href="#" class="d-flex align-items-center justify-content-between">Cash Tracks
                <i class="bx bx-chevron-right nav-arrow-right-icon"></i></a>
              <ul class="admin-submenu2">
                <li v-if="can('cash-payment-view-any')">
                  <router-link :to="{name:'appAccountFinancePaymentCashCollections'}" class="d-flex align-items-center">
                    <i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">All Cash Track</span>
                  </router-link>
                </li>
              </ul>
            </li>

            <!-- <li v-if="can('payment-view-any')">
              <router-link :to="{name:'appAccountFinancePaymentsList'}" class="d-flex align-items-center"> <span class="icon-left-spacing">All Payments </span>
              </router-link>
            </li> -->
            <!-- <li v-if="can('cash-payment-view-any')">
              <router-link :to="{name:'appAccountFinancePaymentCashCollections'}"
                           class="d-flex align-items-center justify-content-between">cash tracking
              </router-link>
            </li> -->
            <!--account & finance:refund start-->
            <li v-if="canAny(['refund-view-any','refund-create'])">
              <a href="#" class="d-flex align-items-center justify-content-between">Refunds
                <i class="bx bx-chevron-right nav-arrow-right-icon"></i></a>
              <ul class="admin-submenu2">
                <li v-if="can('refund-view-any')">
                  <router-link :to="{name:'appRefundList'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span
                      class="icon-left-spacing">All Refund</span>
                  </router-link>
                </li>
                <li v-if="can('refund-create')">
                  <router-link :to="{name:'appRefundCreate'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span
                      class="icon-left-spacing">Add Refund</span>
                  </router-link>
                </li>
              </ul>
            </li>
            <!--account & finance:refund end-->


            <!--account & finance:technician payment start-->
            <li v-if="canAny(['technician-commission-payment-view-any', 'technician-commission-payment-view'])">
              <a href="#" class="d-flex align-items-center justify-content-between">Technician Payments
                <i class="bx bx-chevron-right nav-arrow-right-icon"></i></a>
              <ul class="admin-submenu2">
                <li v-if="can('technician-commission-payment-view-any')">
                  <router-link :to="{name:'appTechnicianCommissionPaymentList'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">All Payment</span>
                  </router-link>
                </li>
                <li v-if="can('technician-commission-payment-view')">
                  <router-link :to="{name:'appTechnicianCommissionPaymentCreate'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">Generate Payment</span>
                  </router-link>
                </li>
              </ul>
            </li>
            <!--account & finance:technician payment end-->

            <!--account & finance:income report menu start-->
            <li>
              <router-link :to="{name:'appAccountFinanceIncomeCreate'}" class="d-flex align-items-center">
                <span>Income Report</span>
              </router-link>
            </li>
            <!--account & finance:income report menu end-->
          </ul>
        </li>
        <!--  account & finance menu end  -->

        <!--others Menu start-->
        <li v-if="canAny([
            'role-view-any', 'role-create',
            'vacancy-view-any', 'vacancy-create',
            'blog-category-view-any', 'blog-category-create',
            'blog-view-any', 'blog-create',
            'event-calendar-view-any', 'event-calendar-create',
            ])" class="menu-main-li">
          <a href="#" class="d-flex align-items-lg-top align-items-xl-center">
            <i class='bx bxs-extension icon-right-spacing'></i>
            <span>Others</span> <i class="bx bx-chevron-down nav-arrow-right-icon icon-left-spacing "></i>
          </a>
          <ul class="admin-submenu">
            <!--others:role menu start-->
            <li v-if="canAny(['role-view-any', 'role-create'])">
              <a href="#" class="d-flex align-items-center justify-content-between">Roles
                <i class="bx bx-chevron-right nav-arrow-right-icon"></i>
              </a>
              <ul class="admin-submenu2">
                <li v-if="can('role-view-any')">
                  <router-link :to="{name:'appRoleList'}" class="d-flex align-items-center">
                    <i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i>
                    <span class="icon-left-spacing">All Role</span>
                  </router-link>
                </li>
                <li v-if="can('role-create')">
                  <router-link :to="{name:'appRoleCreate'}" class="d-flex align-items-center">
                    <i class="bx bx-right-arrow-alt nav-arrow-right-icon"></i>
                    <span class="icon-left-spacing">Add Role</span>
                  </router-link>
                </li>
              </ul>
            </li>
            <!--others:role menu end-->
            <!--others:vacancy menu start-->
            <li v-if="canAny(['vacancy-view-any', 'vacancy-create'])">
              <a href="#" class="d-flex align-items-center justify-content-between">Vacancies<i
                  class="bx bx-chevron-right nav-arrow-right-icon"></i></a>
              <ul class="admin-submenu2">
                <li v-if="can('vacancy-view-any')">
                  <router-link :to="{name:'appVacancyList'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">All Vacancy</span>
                  </router-link>
                </li>
                <li v-if="can('vacancy-create')">
                  <router-link :to="{name:'appVacancyCreate'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">Add Vacancy</span>
                  </router-link>
                </li>
              </ul>
            </li>
            <!--others:vacancy menu end-->
            <!--others:blog category menu start-->
            <li v-if="canAny(['blog-category-view-any', 'blog-category-create'])">
              <a href="#" class="d-flex align-items-center justify-content-between">Blog Categories<i
                  class="bx bx-chevron-right nav-arrow-right-icon"></i></a>
              <ul class="admin-submenu2">
                <li v-if="can('blog-category-view-any')">
                  <router-link :to="{name:'appBlogCategoryList'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">All Blog Category</span>
                  </router-link>
                </li>
                <li v-if="can('blog-category-create')">
                  <router-link :to="{name:'appBlogCategoryCreate'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">Add Blog Category</span>
                  </router-link>
                </li>
              </ul>
            </li>
            <!--others:blog category menu end-->
            <!--others:blog menu start-->
            <li v-if="canAny(['blog-view-any', 'blog-create'])">
              <a href="#" class="d-flex align-items-center justify-content-between">Blogs<i
                  class="bx bx-chevron-right nav-arrow-right-icon"></i></a>
              <ul class="admin-submenu2">
                <li v-if="can('blog-view-any')">
                  <router-link :to="{name:'appBlogList'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span
                      class="icon-left-spacing">All Blog</span>
                  </router-link>
                </li>
                <li v-if="can('blog-create')">
                  <router-link :to="{name:'appBlogCreate'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span
                      class="icon-left-spacing">Add Blog</span>
                  </router-link>
                </li>
              </ul>
            </li>
            <!--others:blog menu end-->
            <!--others:eventCalender menu start-->
            <li v-if="canAny(['event-calendar-view-any', 'event-calendar-create'])">
              <a href="#" class="d-flex align-items-center justify-content-between">Event Calenders<i
                  class="bx bx-chevron-right nav-arrow-right-icon"></i></a>
              <ul class="admin-submenu2">
                <li v-if="can('event-calendar-view-any')">
                  <router-link :to="{name:'appEventCalendarList'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span
                      class="icon-left-spacing">All Calendar Event</span>
                  </router-link>
                </li>
                <li v-if="can('event-calendar-create')">
                  <router-link :to="{name:'appEventCalendarCreate'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span
                      class="icon-left-spacing">Add Calendar Event</span>
                  </router-link>
                </li>
              </ul>
            </li>
            <!--others:eventCalender menu end-->

            <!--others:customerFeedback menu start-->
            <li v-if="canAny(['customer-feedback-view-any'])">
              <router-link :to="{name:'appCustomerFeedbackList'}" class="d-flex align-items-center">
                <span>Customer Feedback</span>
              </router-link>
            </li>
            <!--others:customerFeedback menu end-->
            <!--others:adminTask menu start-->
            <li v-if="canAny(['admin-task-view-any'])">
              <router-link :to="{name:'appAdminTaskList'}" class="d-flex align-items-center">
                <span>Admin Tasks</span>
              </router-link>
            </li>
            <!--others:adminTask menu end-->
            <!--others:customerInquiryResponse menu start-->
            <li v-if="canAny(['follow-up-view-any'])">
              <router-link :to="{name:'appInquiryResponsesList'}" class="d-flex align-items-center">
                <span>Inquiry Response</span>
              </router-link>
            </li>
            <!--others:customerInquiryResponse menu end-->

            <!--others:scam menu start-->
            <li v-if="canAny(['post-view-any', 'post-create'])">
              <a href="#" class="d-flex align-items-center justify-content-between">Scams<i
                  class="bx bx-chevron-right nav-arrow-right-icon"></i></a>
              <ul class="admin-submenu2">
                <li v-if="can('post-view-any')">
                  <router-link :to="{name:'appScamAlertList'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span
                      class="icon-left-spacing">All Scam</span>
                  </router-link>
                </li>
                <li v-if="can('post-create')">
                  <router-link :to="{name:'appScamAlertCreate'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span
                      class="icon-left-spacing">Add Scam</span>
                  </router-link>
                </li>
              </ul>
            </li>
            <!--others:scam menu end-->

            <!--others:follow-up menu start-->
            <li v-if="canAny(['follow-up-view-any', 'follow-up-create'])">
              <router-link :to="{name:'appFollowUpList'}" class="d-flex align-items-center">
                <span>Follow-ups</span>
              </router-link>
            </li>
            <!--others:follow-up menu end-->

            <!--others:follow-up menu start-->
            <li v-if="canAny(['short-text-template-view-any', 'short-text-template-create'])">
              <router-link :to="{name:'appShortTextTemplate'}" class="d-flex align-items-center">
                <span>Text templates</span>
              </router-link>
            </li>
            <!--others:follow-up menu end-->

          </ul>
        </li>
        <!--others Menu end-->
        <!--setting Menu start-->
        <li class="menu-main-li" v-if="canAny([
                'setting-default-view-any',
                'setting-default-update',
                'setting-google-map-view-any',
                'setting-google-map-update',
                'setting-notification-view-any',
                'setting-notification-update',
                'setting-appointment-view-any',
                'setting-appointment-update',
                'setting-service-view-any',
                'setting-service-update',
                'setting-product-view-any',
                'setting-product-update',
                'setting-product-item-view-any',
                'setting-product-item-update',
                'setting-order-view-any',
                'setting-order-update',
                'setting-discount-view-any',
                'setting-discount-update',
                'setting-franchisee-payment-view-any',
                'setting-franchisee-payment-update',
                'setting-loyalty-point-view-any',
                'setting-loyalty-point-update',
                'setting-payment-view-any',
                'setting-payment-update',
                'setting-technician-commission-payment-view-any',
                'setting-technician-commission-payment-update',
                'setting-coupon-view-any',
                'setting-coupon-update',
                'setting-call-request-view-any',
                'setting-call-request-update',
                'setting-contact-view-any',
                'setting-contact-update',
                'setting-vacancy-view-any',
                'setting-vacancy-update',
                'setting-technician-view-any',
                'setting-technician-update',
                'setting-leave-application-view-any',
                'setting-leave-application-update',
                'setting-customer-view-any',
                'setting-customer-update',
                'setting-franchisee-view-any',
                'setting-franchisee-update',
                'setting-vendor-view-any',
                'setting-vendor-update'
                ])">
          <a href="" class="d-flex align-items-lg-top align-items-xl-center">
            <i class='bx bxs-cog icon-right-spacing'></i>
            <span>Settings</span> <i class="bx bx-chevron-down nav-arrow-right-icon icon-left-spacing "></i>
          </a>
          <ul class="admin-submenu">

            <!--default settings start-->
            <li v-if="canAny(['setting-default-view-any','setting-default-update'])">
              <a href="#" class="d-flex align-items-center justify-content-between">Default Settings
                <i class="bx bx-chevron-right nav-arrow-right-icon"></i></a>
              <ul class="admin-submenu2" v-if="canAny(['setting-default-view-any','setting-default-update'])">
                <li v-if="canAny(['setting-default-view-any','setting-default-update'])">
                  <router-link :to="{name:'settingsDefaultCompanyList'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">Company Settings</span>
                  </router-link>
                </li>
                <li v-if="canAny(['setting-default-view-any','setting-default-update'])">
                  <router-link :to="{name:'settingsDefaultMobAppList'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">Mobile App Settings</span>
                  </router-link>
                </li>
                <li v-if="canAny(['setting-default-view-any','setting-default-update'])">
                  <router-link :to="{name:'settingsDefaultSocialLinkList'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">Social Link Settings</span>
                  </router-link>
                </li>
                <li v-if="canAny(['setting-default-view-any','setting-default-update'])">
                  <router-link :to="{name:'settingsDefaultSystemList'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">System Settings</span>
                  </router-link>
                </li>
                <li v-if="canAny(['setting-default-view-any','setting-default-update'])">
                  <router-link :to="{name:'settingsDefaultWebsiteList'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">Website Settings</span>
                  </router-link>
                </li>
                <li v-if="canAny(['setting-default-view-any','setting-default-update'])">
                  <router-link :to="{name:'settingDefaultSmsTemplate'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">Short SMS Template</span>
                  </router-link>
                </li>
              </ul>
            </li>

            <!--google map settings start-->
            <li v-if="canAny(['setting-google-map-view-any','setting-google-map-update'])">
              <router-link :to="{name:'settingsGoogleMapList'}">Google Map Settings</router-link>
            </li>

            <!--notification settings start-->
            <li v-if="canAny(['setting-notification-view-any','setting-notification-update'])">
              <router-link :to="{name:'settingsNotificationList'}">Notification Settings</router-link>
            </li>

            <!--appointment settings start-->
            <li v-if="canAny(['setting-appointment-view-any','setting-appointment-update'])">
              <router-link :to="{name:'settingsAppointmentList'}">Appointment Settings</router-link>
            </li>

            <!--service settings start-->
            <li v-if="canAny(['setting-service-view-any','setting-service-update'])">
              <router-link :to="{name:'settingsServiceList'}">Service Settings</router-link>
            </li>

            <!--product/product item settings start-->
            <li v-if="canAny(['setting-product-view-any','setting-product-update','setting-product-item-view-any','setting-product-item-update',])">
              <a href="#" class="d-flex align-items-center justify-content-between">Product Settings
                <i class="bx bx-chevron-right nav-arrow-right-icon"></i></a>
              <ul class="admin-submenu2"
                  v-if="canAny(['setting-product-view-any','setting-product-update','setting-product-item-view-any','setting-product-item-update',])">
                <li v-if="canAny(['setting-product-view-any','setting-product-update'])">
                  <router-link :to="{name:'settingsProductList'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">Product Settings</span>
                  </router-link>
                </li>
                <li v-if="canAny(['setting-product-item-view-any','setting-product-item-update'])">
                  <router-link :to="{name:'settingsProductItemList'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">Product Item Settings</span>
                  </router-link>
                </li>
              </ul>
            </li>

            <!--order settings start-->
            <li v-if="canAny(['setting-order-view-any','setting-order-update'])">
              <router-link :to="{name:'settingsOrderList'}">Order Settings</router-link>
            </li>

            <!--payment related settings start-->
            <li v-if="canAny([
                'setting-discount-view-any',
                'setting-discount-update',
                'setting-franchisee-payment-view-any',
                'setting-franchisee-payment-update',
                'setting-loyalty-point-view-any',
                'setting-loyalty-point-update',
                'setting-payment-view-any',
                'setting-payment-update',
                'setting-technician-commission-payment-view-any',
                'setting-technician-commission-payment-update',
            ])">
              <a href="#" class="d-flex align-items-center justify-content-between">Payment Settings
                <i class="bx bx-chevron-right nav-arrow-right-icon"></i></a>
              <ul class="admin-submenu2" v-if="canAny([
                  [
                'setting-discount-view-any',
                'setting-discount-update',
                'setting-franchisee-payment-view-any',
                'setting-franchisee-payment-update',
                'setting-loyalty-point-view-any',
                'setting-loyalty-point-update',
                'setting-payment-view-any',
                'setting-payment-update',
                'setting-technician-commission-payment-view-any',
                'setting-technician-commission-payment-update',
            ]
              ])">
                <li v-if="canAny(['setting-discount-view-any','setting-discount-update'])">
                  <router-link :to="{name:'settingsNotificationList'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">Discount Settings</span>
                  </router-link>
                </li>
                <li v-if="canAny(['setting-franchisee-payment-view-any','setting-franchisee-payment-update'])">
                  <router-link :to="{name:'settingsNotificationList'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">Franchisee Payment Settings</span>
                  </router-link>
                </li>
                <li v-if="canAny(['setting-loyalty-point-view-any','setting-loyalty-point-update'])">
                  <router-link :to="{name:'settingsNotificationList'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">Loyalty Point Settings</span>
                  </router-link>
                </li>
                <li v-if="canAny(['setting-payment-view-any','setting-payment-update'])">
                  <router-link :to="{name:'settingsNotificationList'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">Payment Settings</span>
                  </router-link>
                </li>
                <li v-if="canAny(['setting-technician-commission-payment-view-any','setting-technician-commission-payment-update'])">
                  <router-link :to="{name:'settingsNotificationList'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">Technician Commission Payment Settings</span>
                  </router-link>
                </li>
              </ul>
            </li>

            <!--coupon settings start-->
            <li v-if="canAny(['setting-coupon-view-any','setting-coupon-update'])">
              <router-link :to="{name:'settingsCouponList'}">Coupon Settings</router-link>
            </li>

            <!--call request settings start-->
            <li v-if="canAny(['setting-call-request-view-any','setting-call-request-update'])">
              <router-link :to="{name:'settingsCallRequestList'}">Call Request Settings</router-link>
            </li>

            <!--contact settings start-->
            <li v-if="canAny(['setting-contact-view-any','setting-contact-update'])">
              <router-link :to="{name:'settingsContactList'}">Contact Settings</router-link>
            </li>

            <!--vacancy settings start-->
            <li v-if="canAny(['setting-vacancy-view-any','setting-vacancy-update'])">
              <router-link :to="{name:'settingsVacancyList'}">Vacancy Settings</router-link>
            </li>

            <!--vacancy settings start-->
            <li v-if="canAny(['setting-technician-view-any','setting-technician-update'])">
              <router-link :to="{name:'settingsNotificationList'}">Technician Settings</router-link>
            </li>

            <!--leave application settings start-->
            <li v-if="canAny(['setting-leave-application-view-any','setting-leave-application-update'])">
              <router-link :to="{name:'settingsNotificationList'}">Leave Application Settings</router-link>
            </li>

            <!--user related settings start-->
            <li v-if="canAny([
                'setting-customer-view-any',
                'setting-customer-update',
                'setting-franchisee-view-any',
                'setting-franchisee-update',
                'setting-vendor-view-any',
                'setting-vendor-update'
            ])">
              <a href="#" class="d-flex align-items-center justify-content-between">User Settings
                <i class="bx bx-chevron-right nav-arrow-right-icon"></i></a>
              <ul class="admin-submenu2" v-if="canAny([
                  'setting-customer-view-any',
                  'setting-customer-update',
                  'setting-franchisee-view-any',
                  'setting-franchisee-update',
                  'setting-vendor-view-any',
                  'setting-vendor-update'
              ])">
                <li v-if="canAny(['setting-customer-view-any','setting-customer-update'])">
                  <router-link :to="{name:'settingsCustomerList'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">Customer Settings</span>
                  </router-link>
                </li>
                <li v-if="canAny(['setting-franchisee-view-any','setting-franchisee-update'])">
                  <router-link :to="{name:'franchiseeSettingList'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">Franchisee Settings</span>
                  </router-link>
                </li>
                <li v-if="canAny(['setting-vendor-view-any','setting-vendor-update'])">
                  <router-link :to="{name:'settingVendorList'}" class="d-flex align-items-center"><i
                      class="bx bx-right-arrow-alt nav-arrow-right-icon"></i> <span class="icon-left-spacing">Vendor Settings</span>
                  </router-link>
                </li>
              </ul>
            </li>

            <!--vacancy settings start-->
            <li v-if="canAny(['email-template-view-any','email-template-update'])">
              <router-link :to="{name:'emailTemplates'}">Email Template Setting</router-link>
            </li>
            <li v-if="canAny(['sms-template-view-any','sms-template-update'])">
              <router-link :to="{name:'smsTemplates'}">Sms Template Setting</router-link>
            </li>
          </ul>
        </li>
        <!--setting Menu end-->

      </ul>
    </nav>
  </div>
</template>

<script>
import Authorization from "@/components/backEnd/mixins/Authorization";

export default {
  name: "Menu",
  mixins: [Authorization]
};
</script>

<style scoped>
/* update css  */
i {
  color: #5a8dee;
}

.admin-menu {
  list-style: none;
  display: flex;
  padding: 0;
  margin-bottom: 0;
}

.admin-menu > li,
.admin-menu > a {
  display: block;
}

.admin-menu li {
  position: relative;

}

.menu-main-li {
  padding: 10px;
}

.admin-menu a {
  display: block;
  text-decoration: none;
  color: #475F7B;
  padding: 10px;
  transition: all .4s;
  text-transform: capitalize;
}

.admin-menu a:hover {
  background-color: #e9ecec;
  color: #727e8c;
  border-radius: 5px;
}

.admin-submenu {
  list-style: none;
  padding: 0;
  position: absolute;
  background-color: white;
  min-width: 270px;
  left: 0;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  box-shadow: -7px 8px 16px 0 rgba(55, 70, 20, 0.2);
  border-radius: 5px;
  top: 53px;
  border: 1px solid #DFE3E7;
  margin-top: 8px !important;
  /* padding: 5px 0px; */

}

.admin-submenu a {
  padding: 0.7rem 1.3rem;
}

.admin-submenu2 {
  position: absolute;
  list-style: none;
  padding: 0;
  left: 100%;
  top: 0;
  min-width: 270px;
  background-color: white;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  box-shadow: -7px 8px 16px 0 rgba(55, 70, 20, 0.2);
  border-radius: 5px;
  border: 1px solid #DFE3E7;

}

.admin-submenu2 a {
  padding: 0.7rem 1.3rem;
}

.admin-menu li:hover > .admin-submenu {
  opacity: 1;
  visibility: visible;
}

.admin-submenu li:hover > .admin-submenu2 {
  opacity: 1;
  visibility: visible;
}

.nav-arrow-right-icon {
  color: #475F7B;
  font-size: 16px;
}

.icon-left-spacing {
  padding-left: 4px;
}

.icon-right-spacing {
  padding-right: 2px;
}

/* media query */

@media only screen and (max-width: 1288px) {
  .admin-submenu {
    min-width: 190px;
  }

  .admin-submenu a {
    padding: 0.7rem 1rem;
  }

  .admin-submenu2 {

    min-width: 190px;

  }
}

/* 1422 */
@media only screen and (max-width: 1422px) and (min-width: 1337px) {
  .menu-main-li {
    padding: 5px 5px 5px 0;
  }

}

@media only screen and (max-width: 1337px) and (min-width: 1100px) {
  .icon-right-spacing {
    display: none;
  }

}
</style>
